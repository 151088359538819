body {
    margin: 0;
    font-family: "CircularStd-Light", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    letter-spacing: -0.3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Moranga';
    src: url(./fonts/Moranga-Regular.ttf) format("truetype")
}

@font-face {
    font-family: 'CircularStd-Light';
    src: url(./fonts/CircularStd-Light.ttf) format("truetype")
}

@font-face {
    font-family: 'CircularStd-Book';
    src: url(./fonts/CircularStd-Book.ttf) format("truetype")
}